.glassmorphic-container-curve {
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(5px);
  border-radius: 25px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding-left: 1em;
  padding-right: 1em;
}

.glassmorphic-container-curve-calculator {
  background-image: linear-gradient(
    to bottom right,
    rgb(255, 255, 255),
    rgb(255, 255, 255)
  );
  border-radius: 20px;
  padding: 10px;
}

.custom-label {
  font-size: "1.5em";
  text-align: "left";
  color: "#2e3664";
  font-weight: "400px";
}

.bottom-div {
  position: absolute;
  bottom: 0;
  color: white;
  background-color: #2e3664;
  margin-left: -36px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding-top: 10px;
  text-align: center;
}

.rate-font {
  font-weight: bolder;
  font-size: 1.3em;
}

.con-title {
  text-align: left;
  font-size: medium;
}
.con-value {
  text-align: right;
  font-size: medium;
}

.input-custom-style {
  line-height: 2em;
}

.input-custom-style {
  line-height: 2.1em;
}

#icon {
  position: absolute;
  right: 30px;
  top: 10px;
  width: 24px;
  height: 24px;
}

.transaction-success {
  max-width: 450px; /* Adjust the max-width as needed */
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px; /* Add padding for better aesthetics */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add box shadow for a subtle effect */
}

/* General styling */
.dummy-positioning {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon styling */
/* Variables */

/* Structure */
.success-icon {
  display: inline-block;
  width: 8em;
  height: 8em;
  font-size: 20px;
  border-radius: 50%;
  border: 4px solid #4cc93f;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  transform-origin: center;
  animation: showSuccess 180ms ease-in-out;
  transform: scale(1);
}

/* Elements */
.success-icon__tip,
.success-icon__long {
  display: block;
  position: absolute;
  height: 4px;
  border-radius: 10px;
  background-color: #4cc93f;
}

.success-icon__tip {
  width: 2.4em;
  top: 4.3em;
  left: 1.4em;
  transform: rotate(45deg);
  animation: tipInPlace 300ms ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 180ms;
  visibility: hidden;
}

.success-icon__long {
  width: 4em;
  transform: rotate(-45deg);
  top: 3.7em;
  left: 2.75em;
  animation: longInPlace 140ms ease-in-out;
  animation-fill-mode: forwards;
  visibility: hidden;
  animation-delay: calc(300ms + 140ms);
}

/* Keyframes */
@keyframes showSuccess {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes tipInPlace {
  from {
    width: 0em;
    top: 0em;
    left: -1.6em;
  }
  to {
    width: 2.4em;
    top: 4.3em;
    left: 1.4em;
    visibility: visible;
  }
}

@keyframes longInPlace {
  from {
    width: 0em;
    top: 5.1em;
    left: 3.2em;
  }
  to {
    width: 4em;
    top: 3.7em;
    left: 2.75em;
    visibility: visible;
  }
}
