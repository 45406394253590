/* .glassmorphic-container-curve {
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(5px);
  border-radius: 25px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding-left: 3em;
  padding-right: 3em;
} */

.glassmorphic-container-curve-blue {
  background-image: linear-gradient(
    to bottom right,
    rgba(231, 240, 254, 0.9),
    rgba(231, 240, 254, 0.1)
  );
  backdrop-filter: blur(5px);
  border-radius: 25px;
  padding: 20px;
  padding-left: 3em;
  padding-right: 3em;
  box-shadow: 1px 6px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.custom-label {
  font-size: "1.5em";
  text-align: "left";
  color: "#2e3664";
  font-weight: "400px";
}

.bottom-div {
  position: absolute;
  bottom: 0;
  color: white;
  background-color: #2e3664;
  margin-left: -36px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding-top: 10px;
  text-align: center;
}

.rate-font {
  font-weight: bolder;
  font-size: 1.3em;
}

.con-title {
  text-align: left;
}
.con-value {
  text-align: right;
}

.input-custom-style {
  line-height: 2em;
}

.input-custom-style {
  line-height: 2.1em;
}

#icon {
  position: absolute;
  right: 30px;
  top: 10px;
  width: 24px;
  height: 24px;
}
